<template>
  <div class="news">
    <div class="container" style="width: 812px;">
      <div class="consumer-header">
        <div class="consumer-logo"><img src="../../assets/logo2.png" width="140"></div>
        <h1>{{ $t('nav.consumerInsights') }}</h1>
        <p>{{ $t('consumerDesc') }}</p>
        <p>{{ $t('consumerDesc2') }}</p>
        <el-button type="primary" @click="drawer = true" v-if="teams.length > 0">{{ $t('ResearchTeam') }}</el-button>
      </div>

      <ul class="category-nav" v-if="categoryList.length > 0">
        <li v-for="nav in categoryList" :key="nav.id">
          <a href="javascript:;" :class="{ active: nav.id == currentCategory.id }" @click="changeCategory(nav)">{{ getText(nav, 'name') }}</a>
        </li>
      </ul>
      <div class="news-list insight-list" v-if="newsList.length > 0">
        <div class="item" v-for="item in newsList" :key="item.id">
          <div class="item-pic" @click="toNewsDetail(item)"><el-image style="width: 168px; height: 110px" :src="item.coverImage" fit="cover"></el-image></div>
          <div class="item-content">
            <div class="title" @click="toNewsDetail(item)">{{ getText(item, 'title') }}</div>
            <el-row type="flex" justify="space-between">
              <div><div class="category-list" v-if="item.categoryList.length > 0"><div class="category" v-for="cate in item.categoryList" :key="cate.name">{{ getText(cate, 'name') }}</div></div></div>
              <div>
                <a v-if="item.showDownload && item.docUrl" :href="item.docUrl" target="_blank" download class="el-button el-button--primary el-button--mini">{{ $t('fileDownload') }}</a>
                <el-button type="primary" size="mini" @click="toPdf(item.docUrl)" v-if="item.showView && item.docUrl">{{ $t('ViewReport') }}</el-button>
              </div>
            </el-row>
          </div>
        </div>
        <el-pagination @current-change="getList" :current-page.sync="page" :page-size="size" background layout="prev, pager, next" :total="totalElements" :hide-on-single-page="true"></el-pagination>
      </div>
    </div>

    <!-- 研究院团队 -->
    <el-drawer :title="$t('ResearchTeam')" :size="570" :visible.sync="drawer" :direction="direction">
      <div class="team-list">
        <div class="item" v-for="item in teams" :key="item.name">
          <div class="item-pic">
            <el-image style="width: 126px; height: 155px" :src="item.avatar" fit="cover"></el-image>
          </div>
          <div class="item-content">
            <div class="name">{{ getText(item, 'name') }}</div>
            <div class="title" v-if="item.positional">{{ getText(item, 'positional') }}</div>
            <div class="desc">{{ getText(item, 'introduce') }}</div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      size: 20,
      page: 1,
      totalElements: 0,
      newsList: [],
      categoryList: [],
      currentCategory: null,
      drawer: false,
      direction: 'rtl',
      teams: []
    }
  },
  created() {
    this.getCategoryList()
    this.getTeams()
  },
  methods: {
    async getList() {
      const res = await api.getArticles(
        {
          articleTypes: ['CONSUMER_RESEARCH'],
          categoryId: this.currentCategory.id,
        },
        `size=${this.size}&page=${this.page - 1}`
      )

      const { content, totalElements } = res.data
      this.newsList = content
      this.totalElements = totalElements
    },
    getCategoryList() {
      api.categoryList({ categoryTypes: ['CONSUMER_RESEARCH'] }).then((res) => {
        this.categoryList = res.data
        this.currentCategory = this.categoryList[0]
        this.page = 1
        this.getList()
      })
    },
    changeCategory(nav) {
      this.currentCategory = nav
      this.page = 1
      this.getList()
    },
    getTeams() {
      api.$get('/foundation/api/article/getArticleFounderList').then((res) => {
        this.teams = res.data
      })
    },
  },
}
</script>
